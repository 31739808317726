.card {
    width: 300px;
    height: 450px;
    margin: 10px;
}

.card-item {
    max-height: 70px;
}

.title {
    font-size: 40px;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 0px;
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
}

.card-title {
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.bkg {
    width: 100%;
    height: 100px;
    margin: 0px;
    margin-top: 0px;
    overflow: hidden;
    background-color: #F0F0F0;
    z-index: -100;
}

.description {
    margin: 10px;
    color: #808090;
    background: transparent;
    z-index: 1;
}

.rotated {
    margin-top: -4%;
    transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    width: 100%;
    height: 200px;
    opacity: 1;
    z-index: -110;
}

.cards-layout {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}