.login-page-root {
    width: 100vw;
    height: 100vh;
}

.login-box-panel {
    background-color: #aeb8fe;
    fill: #aeb8fe;
    stroke: #aeb8fe;
}

.login-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.login-box {
    flex: 1 1 0;
    max-height: 500px;
    margin: 40px;
    border-radius: 10px;
    /* makes the inner div clip to the round corners */
    overflow: hidden;
}

.branding-box-panel {
    width: 65%;
    background-color: #27187e;
    fill: white;
    stroke: white;
    color: white;
}

.branding-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.branding-footer {
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.branding-text {
    font-family: Times New Roman;
}

.language-picker {
    margin-bottom: 10px;
    align-self: stretch;
}

@keyframes flashing {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flashing{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flashing{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flashing{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }

.pending-animation {
    -webkit-animation: flashing 1s infinite;
    -moz-animation: flashing 1s infinite;
    -o-animation: flashing 1s infinite;
    animation: flashing 1s infinite;
}