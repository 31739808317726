.shaken {
    animation: shaken 0.5s;
    animation-iteration-count: 1;
}

@keyframes shaken {
    0% { transform: translate(10px, 0px); }
    20% { transform: translate(-10px, 0px); }
    40% { transform: translate(10px, 0px); }
    60% { transform: translate(-10px, 0px); }
    100% { transform: translate(0px, 0px); }
  }

.flashing {
    animation: flashing 1s linear infinite;
}
  
@keyframes flashing {
    30% { opacity: 0; }
    70% { opacity: 1; }
}